import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('https://apitgm.nexu.com.ar/protected-endpoint', { withCredentials: true });

        if (response.status === 200) { // Verifica si el estado es 200
          setUser(response.data.user); // Accede a response.data
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = useCallback((userData) => {
    setUser(userData);
    Cookies.set('user', JSON.stringify(userData), { expires: 1, sameSite: 'None', secure: true }); // Configura la cookie con SameSite y Secure
    navigate(`/${userData.role}/dashboard`);
  }, [navigate]);

  const logout = useCallback(async () => {
    try {
      const response = await axios.post('https://apitgm.nexu.com.ar/logout', {}, { withCredentials: true });

      if (response.status === 200) {
        setUser(null);
        Cookies.remove('user'); // Elimina la cookie
        navigate('/login');
      } else {
        console.error('Error logging out');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }, [navigate]);

  const checkTokenExpiration = useCallback(async () => {
    if (!user) return;

    try {
      const response = await axios.get('https://apitgm.nexu.com.ar/protected-endpoint', { withCredentials: true });

      if (response.status === 200) {
        setUser(response.data.user);
      } else {
        logout(); // Si el token es inválido o expirado, cierra la sesión
      }
    } catch (error) {
      console.error('Error checking token expiration:', error);
      logout();
    }
  }, [user, logout]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Revisa cada minuto

    return () => clearInterval(interval);
  }, [checkTokenExpiration]);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
